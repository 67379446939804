import {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

export type CollapsibleSectionProps = {
  title: string;
  disabled?: boolean;
  renderSelection?: ReactElement;
  expanded?: boolean;
  showResult?: boolean;
  className?: string;
};
export const CollapsibleToggleContext = createContext({
  toggle: () => {},
});
export const CollapsibleSection: FC<
  PropsWithChildren<CollapsibleSectionProps>
> = ({
  title,
  children,
  renderSelection,
  disabled,
  showResult = true,
  className,
}) => {
  const [collapsed, setCollapsed] = useState(!showResult);
  let showSelection: boolean;

  if (disabled) {
    showSelection = false;
  } else if (showResult) {
    showSelection = true;
  } else {
    showSelection = collapsed;
  }

  return (
    <div
      className={`flex flex-col ${
        disabled ? 'cursor-not-allowed opacity-50' : ''
      }} ${className || ''}`}
    >
      <div
        className="flex flex-row hover:cursor-pointer"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <span>{title}</span>
        <div className="flex grow justify-end">
          <button className="disabled:cursor-not-allowed" disabled={disabled}>
            {collapsed ? (
              <ChevronDownIcon width={24} height={24} />
            ) : (
              <ChevronUpIcon width={24} height={24} />
            )}
          </button>
        </div>
      </div>

      {!disabled && (
        <div className="flex flex-col py-[20px]">
          {showSelection && (
            <div
              className={`pb-1 transition-all delay-1000 duration-500 ${
                showSelection ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {renderSelection}
            </div>
          )}

          <CollapsibleToggleContext.Provider
            value={{
              toggle: () => {
                setCollapsed(!collapsed);
              },
            }}
          >
            <div
              className={`overflow-hidden transition-all duration-500 ease-in-out ${
                collapsed ? 'max-h-0 opacity-0' : 'max-h-[1000vh] opacity-100'
              }`}
            >
              {children}
            </div>
          </CollapsibleToggleContext.Provider>
        </div>
      )}
    </div>
  );
};

export default CollapsibleSection;
