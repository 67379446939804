import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import ClampedText from '@/components/molecules/ClampedText';
import { ExtendedOptionsFields } from '@/lib/schema/build/types';
import { FC, useState } from 'react';

const ExtendedOptionCard: FC<{
  option: ExtendedOptionsFields;
  handleAddDispatch: (option: ExtendedOptionsFields) => void;
  handleRemoveDispatch: (option: ExtendedOptionsFields) => void;
  isSelected: boolean;
}> = ({ option, handleAddDispatch, handleRemoveDispatch, isSelected }) => {
  const { msrp, description, mandatory = false } = option;
  const [selected, setSelected] = useState(isSelected);

  const added = selected || mandatory;
  const buttonText = added ? 'Remove' : 'Add';

  const handleOnClick = () => {
    if (selected) {
      handleRemoveDispatch(option);
    } else {
      handleAddDispatch(option);
    }
    setSelected((prev) => !prev);
  };

  return (
    <div
      className={`mb-5 inline-flex min-h-[20px] w-full flex-col items-start justify-start gap-3 rounded border-2 p-5 ${
        added
          ? 'border-black bg-background-light'
          : 'border-gray-300 bg-background-white'
      }`}
    >
      <div className="inline-flex items-start justify-start gap-5 self-stretch">
        <ClampedText
          className="Title w-full text-[16px] text-neutral-800"
          clampLines={2}
          expandable
        >
          {`${description} ${mandatory ? '(Mandatory)' : ''}`}
        </ClampedText>
        <div className="text-right text-body16px leading-snug tracking-tight text-black">
          {`$${msrp.toLocaleString()}`}
        </div>
      </div>
      <div>
        <Button
          aria-label="toggle option"
          variant={added ? ButtonVariants.Primary : ButtonVariants.Secondary}
          onClick={handleOnClick}
          disabled={mandatory}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default ExtendedOptionCard;
