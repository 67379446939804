export const parseRangeWithUnit = (range: { value: number; unit: string }) => {
  const unit = process.env.USE_KM ? 'km' : 'mi';
  if (range.unit === unit) {
    return {
      value: Math.round(range.value),
      unit,
    };
  }
  return {
    value: Math.round(range.value * (unit === 'mi' ? 0.621371 : 1.60934)),
    unit,
  };
};
